/* eslint-disable no-unused-vars */
/**
 * FilterSelect
 */

import React from 'react';
import classNames from 'classnames';
import Select, { createFilter, components } from 'react-select';
import s from './FilterSelect.module.scss';

const FilterSelect = ({
    id,
    value,
    onChange,
    options,
    icon,
    modifier,
    placeholder,
    small,
    isLocation,
    isFullWidth,
    useDefaultValueContainer,
}) => {
    const customStyles = {
        menuList: (provided) => ({
            ...provided,
            borderRadius: '4px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#AF1432' : '#fff',
        }),
    };

    const OptionComp = isLocation ? LocationCustomOption : CustomOption;
    const ValueContainer = useDefaultValueContainer ? components.ValueContainer : CustomValueContainer;

    const classes = classNames(
        s['FilterSelect'],
        {[s['FilterSelect--FullWidth']]: isFullWidth },
        {[s['FilterSelect--Small']]: small },
        {[s['FilterSelect--HasIcon']]: icon },
        {[s[`FilterSelect--${icon}`]]: icon },
        {[s[`FilterSelect--${modifier}`]]: modifier },
    );

    return (
        <div className={classes}>
            <label htmlFor={id} className="sr-only">{placeholder}</label>
            <Select
                inputId={id}
                value={options.filter((option) => {
                    return Array.isArray(value)
                        ? value.includes(option.value)
                        : option.value === value;
                })}
                noOptionsMessage={() => 'Ingen träff'}
                placeholder={placeholder ? placeholder : 'Välj'}
                filterOption={createFilter({ ignoreAccents: false })}
                classNamePrefix="Select"
                styles={customStyles}
                onChange={onChange}
                options={options}
                menuPortalTarget={typeof document !== 'undefined' ? document.querySelector('body') : null}
                components={{
                    Option: OptionComp,
                    ValueContainer: ValueContainer,
                }}
            />
        </div>
    );
};

FilterSelect.defaultProps = {
    id: 'filter-select',
    options: [],
    small: false,
    isLocation: false,
};

const CustomValueContainer = ({ children, isFocused, ...props}) => {
    const { getValue, hasValue } = props;
    const numberOfSelectedOptions = getValue().length;

    if (numberOfSelectedOptions === 1 || !hasValue) {
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    }

    return (
        <components.ValueContainer {...props}>
            <div className={s['FilterSelect__Custom']}>
                {children}
                <span className={s['FilterSelect__CustomNumber']}>+{numberOfSelectedOptions - 1}</span>
            </div>
        </components.ValueContainer>
    );
};

class LocationCustomOption extends React.Component {
    render() {
        const { innerProps, isFocused, ...otherProps } = this.props;
        const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
        const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };
        const { children, data } = this.props;

        const isCounty = data.hasOwnProperty('isCounty') ? data.isCounty : children && children.endsWith(' län');
        const isDefaultOption = !isCounty && children && children === 'Hela Sverige' || children === 'Distans';

        // If location, the items are hierarichal, so parent will be sticky in dropdown
        const classes = classNames(
            s['FilterSelect__CustomOption'],
            s['FilterSelect__CustomOption--Location'],
            {[s['FilterSelect__CustomOption--Parent']]: isCounty && !isDefaultOption},
            {[s['FilterSelect__CustomOption--Child']]: !isCounty && !isDefaultOption},
        );

        return (
            <components.Option {...newProps} className={classes}>
                {children}
            </components.Option>
        );
    }
}

class CustomOption extends React.Component {
    render() {
        const {innerProps, isFocused, children, data, ...otherProps} = this.props;
        const {onMouseMove, onMouseOver, ...otherInnerProps} = innerProps;
        const newProps = {innerProps: {...otherInnerProps}, ...otherProps};

        return (
            <components.Option {...newProps} className={classNames(
                s['FilterSelect__CustomOption'],
                {[s['FilterSelect__CustomOption--Parent']]: !data.indent},
                {[s['FilterSelect__CustomOption--Child']]: data.indent},
            )}>
                {children}
            </components.Option>
        );
    }
}

export default FilterSelect;
