/**
 * ArrangementEntryList
 */

import React from 'react';
import classNames from 'classnames';
import { objToQuery } from 'utils/Helpers';
import { httpGet } from 'utils/Http';
import ArrangementEntry from 'Components/ArrangementEntry';
import ButtonReadMore from 'Components/ButtonReadMore';
import FilterSelect from 'Components/FilterSelect';
import { Waypoint } from 'react-waypoint';
import AjaxLoader from 'Components/AjaxLoader';
import Title from 'Components/Title';
import s from './ArrangementEntryList.module.scss';

class ArrangementEntryList extends React.PureComponent {
    state = {
        showShowAllButton: false,
        mounted: false,
        fetching: true,
        selectedPlace: this.props.place,
        selectedDistance: true,
        type: undefined,
        selectedBusinessConcepts: this.props.preselectedBusinessConcepts,
        selectedBusinessPartners: this.props.preselectedBusinessPartners,
        distanceOnsiteFilter: this.props.distanceOnsiteFilter,
        page: 1,
        count: 0,
        entries: [],
        filterCount: {},
        allowHide: false,
    };

    fetchEntries = (isInitialSearch) => {
        const {
            category,
            visible,
            arrangementType,
            arrangementActivity,
            priorityQuery,
            excludeKeywords,
            sorting,
            currentPage,
            listWithNonSpecific,
        } = this.props;

        const {
            selectedPlace,
            selectedDistance,
            selectedBusinessPartners,
            selectedBusinessConcepts,
            type,
            distanceOnsiteFilter,
            page,
            entries,
            filterCount,
        } = this.state;

        // Use the selected option if specific is set, otherwise use the checkbox value
        // If checkbox is selected, then both onsite and distance is used
        // If checkbox isn't selected, then only onsite is used
        const showDistance = distanceOnsiteFilter !== 'distance' && distanceOnsiteFilter !== 'onsite';
        const setDistanceOnsiteFilter = !showDistance ? distanceOnsiteFilter : (
            selectedDistance ? '' : 'onsite'
        );

        const queries = objToQuery({
            categories: category || '',
            places: selectedPlace || '',
            type: arrangementType || '',
            activity: arrangementActivity || '',
            arrangementType: type || '',
            businessPartners: selectedBusinessPartners ? selectedBusinessPartners.join(',') : '',
            businessConcepts: selectedBusinessConcepts ? selectedBusinessConcepts.join(',') : '',
            priorityQuery: priorityQuery ? priorityQuery.join(',') : '',
            excludeKeywords: excludeKeywords ? excludeKeywords.join(';') : '',
            sorting: sorting || 'default',
            exclude: currentPage || '',
            distanceOnsiteFilter: setDistanceOnsiteFilter,
            fallbackToParentPlace: isInitialSearch ? false : true,
            take: visible || 3,
            page: page || 1,
            listWithNonSpecific: listWithNonSpecific || false,
        });

        const url = `/wt/api/v2/arrangement/filter/${queries}`;

        httpGet(url)
            .then((result) => {
                // Only change this the first time
                const setAllowHide = isInitialSearch && result.count <= 0;
                this.setState({
                    fetching: false,
                    entries: entries.concat(result.results),
                    count: result.count,
                    initialNumberOfCards: visible,
                    showShowAllButton: page === 4,
                    allowHide: setAllowHide,
                    filterCount: isInitialSearch ? result.filter : filterCount,
                });
            })
            .catch(() => {
                this.setState({
                    fetching: false,
                });
            });
    };

    handleOnChangeType = (e) => {
        this.setState(
            {
                type: e.value,
                fetching: true,
                page: 0,
                entries: []
            },
            () => {
                this.fetchEntries(false);
            }
        );
    }

    handleOnChangePlace = (e) => {
        this.setState(
            {
                selectedPlace: e.value > 0 ? parseInt(e.value) : '',
                fetching: true,
                page: 0,
                entries: []
            },
            () => {
                this.fetchEntries(false);
            }
        );
    };

    handleOnChangeDistance = (e) => {
        this.setState(
            {
                selectedDistance: e.target.checked,
                fetching: true,
                page: 0,
                entries: []
            },
            () => {
                this.fetchEntries(false);
            }
        );
    };

    componentDidMount = () => {
        if (typeof window !== 'undefined') {
            this.fetchEntries(true);
        }
    }

    handleListExtension = () => {
        const self = this;

        const { page } = this.state;

        this.setState({
            page: page + 1,
            fetching: true,
        }, () => {
            self.fetchEntries(false);
        });
    }

    render() {
        const {
            title,
            tag,
            places,
            showFilter,
            arrangementActivity,
            pageListUrl,
            extendable,
            listWithNonSpecific,
            distanceOnsiteFilter,
            identifier,
        } = this.props;

        const {
            fetching,
            entries,
            selectedPlace,
            selectedDistance,
            type,
            showShowAllButton,
            count,
            allowHide,
            filterCount,
        } = this.state;

        const classes = classNames(
            s['ArrangementEntryList'],
            {[s['ArrangementEntryList--Filter']]: showFilter},
        );

        const types = [{
            'type': '',
            'label': 'Alla typer'
        }, {
            'type': 'other',
            'label': 'Cirklar/kurser',
        }, {
            'type': 'culture',
            'label': 'Evenemang/arrangemang'
        }].filter(({type}) => {
            return typeof(filterCount['activity_type']) === 'undefined' ||
                typeof(filterCount['activity_type'][type]) === 'undefined' ||
                filterCount['activity_type'][type] > 0;
        });

        // Show distance checkbox if both distance and physical is set
        const showDistance = distanceOnsiteFilter !== 'distance' && distanceOnsiteFilter !== 'onsite';

        // Hide types if only all and one other is available
        const showTypes = !arrangementActivity && types.length > 2;

        if (allowHide && !fetching && (!entries || !entries.length) && !listWithNonSpecific) {
            return null;
        }

        const distanceId = identifier !== '' ? `${identifier}-distance` : 'distance';

        return (
            <section className={classes}>
                <div className={s['ArrangementEntryList__Container']}>
                    <div className={s['ArrangementEntryList__EntryWaypoint']}>
                        <Waypoint onEnter={this.handleWaypointEnter} />
                    </div>
                    <Title title={title} tag={tag}>
                        {showFilter && places && (
                            <div className={s['ArrangementEntryList__Options']}>
                                <div className={s['ArrangementEntryList__Option']}>
                                    <FilterSelect
                                        id={`${identifier}-filter-select-place`}
                                        value={selectedPlace}
                                        placeholder="Välj plats"
                                        onChange={this.handleOnChangePlace}
                                        options={places.map((item) => ({
                                            value: item.id,
                                            label: item.name,
                                            isCounty: item.isCounty,
                                        }))}
                                        icon="Location"
                                        isLocation={true}
                                    />

                                    {showDistance &&
                                        <div className={s['ArrangementEntryList__Checkbox']}>
                                            <input
                                                id={distanceId}
                                                name="distance"
                                                type="checkbox"
                                                checked={selectedDistance === true}
                                                onChange={(e) => this.handleOnChangeDistance(e)}
                                            />
                                            <label htmlFor={distanceId}>
                                                Visa distans
                                            </label>
                                        </div>
                                    }
                                </div>

                                {showTypes && (
                                    <div className={s['ArrangementEntryList__Option']}>
                                        <FilterSelect
                                            id={`${identifier}-filter-select-type-${type || ''}`}
                                            value={type || ''}
                                            placeholder="Välj typ"
                                            onChange={this.handleOnChangeType}
                                            options={types.map((item) => ({
                                                value: item.type,
                                                label: item.label,
                                            }))}
                                            icon="No-Icon"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </Title>

                    {entries && (
                        <div className={s['ArrangementEntryList__EntryList']}>
                            {entries.map((item, index) => (
                                <div
                                    className={s['ArrangementEntryList__Entry']}
                                    key={index}>
                                    <ArrangementEntry {...item} />
                                </div>
                            ))}
                        </div>
                    )}

                    {fetching && (
                        <div className={s['ArrangementEntryList__LoaderWrapper']}>
                            <AjaxLoader />
                        </div>
                    )}

                    {!fetching && (!entries || !entries.length) && (
                        <h3 className={s['ArrangementEntryList__NoHits']}>
                            Tyvärr hittade vi inget som matchar din sökning
                        </h3>
                    )}

                    {!showShowAllButton && !fetching && extendable && entries.length < count && (
                        <div className={s['ArrangementEntryList__ButtonWrapper']}>
                            <ButtonReadMore
                                text="Visa fler"
                                onClick={this.handleListExtension}
                            />
                        </div>
                    )}

                    {((!extendable && pageListUrl) || (showShowAllButton && pageListUrl)) && (
                        <div className={s['ArrangementEntryList__ButtonWrapper']}>
                            <a
                                href={pageListUrl}
                                className={s['ArrangementEntryList__GoToPage']}>
                            Sök fler
                            </a>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

ArrangementEntryList.defaultProps = {
    image: {},
    link: {},
    entries: [],
    identifier: '',
};

export default ArrangementEntryList;
