/**
 * ArrangementEntry
 */

import React from 'react';
import classNames from 'classnames';
import Button from 'Components/Button';
import Image from 'Components/Image';
import s from './ArrangementEntry.module.scss';

class ArrangementEntry extends React.PureComponent {
    render() {
        const {
            title,
            link,
            location,
            image,
            priceList,
            dateShort,
            date,
            isDistance,
            isDistanceHybrid,
            categories,
            onLinkClick = () => null,
        } = this.props;

        const classes = classNames(
            s['ArrangementEntry'],
            {[s['ArrangementEntry--NoImage']]: !image || !image.src},
        );

        const dateText = dateShort || date;
        const distanceOrDistanceHybrid = isDistance || isDistanceHybrid;

        const imageSizes = [
            '(min-width: 1200px) 365px',
            '(min-width: 1024px) 33vw',
            '(min-width: 960px) 50vw',
            '100vw',
        ];

        return (
            <article className={classes}>
                <div className={s['ArrangementEntry__Container']}>
                    {image && image.src && (
                        <div className={s['ArrangementEntry__Image']}>
                            <Image {...image} sizes={imageSizes} />
                        </div>
                    )}

                    <div className={s['ArrangementEntry__Content']}>
                        <div>
                            <div className={classNames(s['ArrangementEntry__MetaList'], s['ArrangementEntry__MetaList--Vertical'])}>
                                {!isDistance && location && (
                                    <div className={
                                        classNames(s['ArrangementEntry__MetaItem'], s['ArrangementEntry__MetaItem--Location'], {
                                            [s['ArrangementEntry__MetaItem--Hybrid']]: isDistanceHybrid,
                                        })}
                                    >
                                        {location}
                                    </div>
                                )}
                                {distanceOrDistanceHybrid && (
                                    <div className={s['ArrangementEntry__Distance']}>
                                        {isDistanceHybrid ? 'Distans / på plats' : 'Distans'}
                                    </div>
                                )}
                            </div>
                            <h3 className={s['ArrangementEntry__Title']}>{title}</h3>
                            {categories && (
                                <div className={s['ArrangementEntry__Categories']}>
                                    {categories.slice(0, 2).map((category, index) => (
                                        <span key={index} className={s['ArrangementEntry__Category']}>
                                            {category.name}
                                        </span>
                                    ))}
                                </div>
                            )}

                            <div className={s['ArrangementEntry__MetaList']}>
                                {priceList && (
                                    <div className={classNames(s['ArrangementEntry__MetaItem'], s['ArrangementEntry__MetaItem--Price'])}>
                                        {priceList}
                                    </div>
                                )}
                                {dateText && (
                                    <div className={classNames(s['ArrangementEntry__MetaItem'], s['ArrangementEntry__MetaItem--Date'])}>
                                        {dateText}
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button
                            {...link}
                            text={<React.Fragment>
                                Läs mer <span className="sr-only"> om {title}</span>
                            </React.Fragment>}
                            onClick={onLinkClick}
                        />
                    </div>
                </div>
            </article>
        );
    }
}

ArrangementEntry.defaultProps = {
    link: {},
    image: {},
};

export default ArrangementEntry;
