/**
 * ButtonReadMore
 */

import React from 'react';
import s from './ButtonReadMore.module.scss';

const ButtonReadMore = ({ text, onClick }) => (
    <button className={s['ButtonReadMore']} onClick={onClick} type="button">
        {text}
    </button>
);

export default ButtonReadMore;
