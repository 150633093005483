import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import classNames from 'classnames';
import s from './Image.module.scss';

const Image = ({
    src = '',
    webp = '',
    width = 0,
    height = 0,
    alt = '',
    caption = '',
    focal = {},
    sizes = [],
    useCover = true,
    useWebp = true,
    className = '',
    lazyLoad = true,
}) => {
    const position = focal && focal?.x && focal?.y ? `${focal.x} ${focal.y}` : 'center center';

    const classes = classNames(
        s['Root'],
        {[s['Root--Cover']]: useCover},
        {[s['Root--Contain']]: !useCover},
        className,
    );

    return (
        <NextImage
            className={classes}
            src={useWebp ? (webp || src) : src}
            width={useCover ? undefined : width}
            height={useCover ? undefined : height}
            alt={alt}
            title={caption}
            fill={useCover}
            sizes={sizes.join(', ')}
            loading={lazyLoad ? 'lazy' : 'eager'}
            style={{
                objectPosition: position,
            }}
        />
    );
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string,
    caption: PropTypes.string,
    sizes: PropTypes.array,
    focal: PropTypes.object,
    useCover: PropTypes.bool,
    useWebp: PropTypes.bool,
    className: PropTypes.string,
    lazyLoad: PropTypes.bool,
};

export default Image;
